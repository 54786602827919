import { useFieldValidation } from '../useFieldValidation'
import type { FieldValidationEntity } from '../useFieldValidation/types'
import { validateRequiredString } from '../useFieldValidation/validateRequiredString'
import type { StringField } from './types'

export const useStringField = (
  props?: Partial<{ required: boolean; key: string }>,
): StringField => {
  const value = ref<string>('')

  const trimmedValue = computed<string>(() => value.value.trim())

  const rules: FieldValidationEntity[] = []

  if (props?.required)
    rules.push([validateRequiredString, 'error.requiredInput'])

  const { error: validationError, validate } = useFieldValidation<string>(rules)

  return {
    data: reactive({
      key: props?.key || 'string',
      value,
      trimmedValue,
      validationError,
    }),

    actions: {
      validate,
    },
  }
}
